import useUpgradeSelection from '@/store/upgradeSelection';
import { Role } from '../model';
import { Middleware, WretchError } from 'wretch';
import QueryStringAddon from "wretch/addons/queryString"
import type { WretchResponseChain, WretchAddon, WretchErrorCallback } from 'wretch';
import wretch from 'wretch';
import * as jose from 'jose';
import * as Sentry from '@sentry/vue';
import type { Coupon } from 'recurly';

interface UnlessResolver {
  unless: <T, C extends UnlessResolver, R>(
    this: C & WretchResponseChain<T, C, R>,
    status: number,
    cb: WretchErrorCallback<T, C, R>,
  ) => this;
}

export interface Tokens {
  access_token: string;
  refresh_token: string;
  expires: number;
  token_type: 'Bearer';
}

interface Payload {
  userid: number;
  email: string;
  iat: number;
  role: 'client' | 'router';
}

// A cached public key getter/filter for jose.jwtVerify to reuse
const jwks = jose.createRemoteJWKSet(new URL(`${window.location.origin}/.well-known/jwks.json`));
export const getToken = async (): Promise<Payload | null> => {
  try {
    const cookies = document.cookie.split('; ').map(v => v.split(/=(.*)/s).map(decodeURIComponent));
    const map = Object.fromEntries(cookies);
    // sharing cookies across subdomains means we need to be careful about staging vs prod cookies
    const targetCookie = import.meta.env.VITE_SESSION_TOKEN_NAME;
    const verified = await jose.jwtVerify(map[targetCookie], jwks);
    const { sub: userid, email, iat, role } = verified.payload;
    return { userid, email, iat, role } as any as Payload;
  } catch (err) {
    return null;
  }
};

// FIXME: this call hits our database for the sake of user deletion
export const isAuthenticated = () =>
  api
    .get('/api/user')
    .res(() => true)
    .catch(() => false);

const identity = <T>(x: T) => x;
const toLogin = () => window.location.href = '/login'; // TODO: redirect back

const unless = (): WretchAddon<unknown, UnlessResolver> => ({
  // The entire middleware just provides the status code to the unless resolver
  // because the resolver cannot return a Promise;
  beforeRequest(wretch, _, state) {
    return wretch.middlewares([
      next => (url, opts) =>
        next(url, opts).then(response => {
          state.unless && state.unless(response.status);
          return response;
        }),
    ]);
  },
  resolver: {
    unless(wanted, cb) {
      this._sharedState.unless = (status: number) =>
        status !== wanted ? this.error(status, cb) : undefined;
      return this;
    },
  },
});

export const api = wretch(window.location.origin)
  .errorType('json')
  .addon(QueryStringAddon)
  .addon(unless());

const auth: Middleware = () => (next) => async (url, { headers, ...opts }) => {
  return next(url, { ...opts, headers });
  // const tokens = JSON.parse(window.localStorage.getItem('tokens') || 'null');
  // if (tokens === null) {
  //   throw TypeError('invalid arguments: missing access_token');
  // }
  //
  // const { access_token, refresh_token, expires } = tokens;
  //
  // // tab may be offloaded
  // if (expires - 60 > Math.floor(Date.now() / 1000)) {
  //   const tokens: Tokens = await wretch('/token')
  //     .post({ refresh_token })
  //     .unauthorized(toLogin)
  //     .json();
  //   window.localStorage.setItem('tokens', JSON.stringify(tokens.access_token));
  // }
  //
  // headers = { ...headers, 'Authorization': `Bearer ${access_token}` };
  // return next(url, { ...opts, headers });
};

export const doQrCodeLogin = (id: string): Promise<any> =>
  api
    .middlewares([auth()])
    .get(`/api/qrcode-login/${id}`)
    .unauthorized(toLogin)
    .res();

export const getUser = (): Promise<any> =>
  api
    .middlewares([auth()])
    .get('/api/account/user')
    .unauthorized(toLogin)
    .error(404, toLogin) // user may be deleted from aaa
    .json();

const success = () => ({ error: false, message: undefined });
const catcher = (err: Error & { original: WretchError }) => {
  Sentry.captureException(err.original ?? err);
  return { error: true, message: err.message || err };
};
const failure = (error: WretchError) => {
  throw { error: true, ...error.json, original: error };
};

export const changeUserPassword = async (currentPassword: string, newPassword: string) =>
  api
    .middlewares([auth()])
    .url('/api/password')
    .put({ currentPassword, newPassword })
    .unauthorized(toLogin)
    .forbidden(() => { throw { error: true, message: 'Current password is incorrect. Not changing password.' }; })
    .json(success)
    .catch(catcher);

export const pauseSubscriptionByReferenceId = async (referenceId: string) =>
  api
    .middlewares([auth()])
    .url(`/api/subscription/${referenceId}`)
    .delete()
    .unauthorized(toLogin)
    .json(success)
    .catch(catcher);

export const cancelSubscriptionByReferenceId = async (referenceId: string) =>
  api
    .middlewares([auth()])
    .url(`/api/subscription/${referenceId}`)
    .delete()
    .unauthorized(toLogin)
    .json(success)
    .catch(catcher);

export const uncancelSubscriptionByReferenceId = async (referenceId: string) =>
  api
    .middlewares([auth()])
    .url(`/api/subscription/${referenceId}/uncancel`)
    .put()
    .unauthorized(toLogin)
    .json(success)
    .catch(catcher);

export const createNewAPIKeyForTeam = async (referenceId: string, description: string) =>
  api
    .middlewares([auth()])
    .url(`/api/subscription/${referenceId}/apiKey`)
    .post({ description })
    .unauthorized(failure)
    .json(body => ({ error: false, response: body }))
    .catch(catcher);

export const revokeAPIKey = async (referenceId: string, apiKey: string) =>
  api
    .middlewares([auth()])
    .url(`/api/subscription/${referenceId}/apiKey/${apiKey}`)
    .delete()
    .unauthorized(toLogin)
    .unless(204, async error => ({ error: true, response: await error.response.json() }))
    .res(success)
    .catch(err => ({ error: true, response: err }));

export const changeQuantity = (referenceId: string, quantity: number) =>
  api
    .middlewares([auth()])
    .url(`/api/subscription/${referenceId}/quantity`)
    .put({ quantity })
    .unauthorized(toLogin)
    .unless(202, failure)
    .json(success)
    .catch(err => ({ error: true, response: err.message || err }));

export const addMemberToTeam = async (
  referenceId: string,
  email: string,
  role: Role,
  firstName: string,
  lastName: string,
  password: string,
) => {
  return await api
    .middlewares([auth()])
    .url(`/api/subscription/${referenceId}/team/member`)
    .post({
      email,
      role,
      firstName,
      lastName,
      password,
    })
    .error(409, () => { throw {
      error: true,
      isPaid: true,
      context: 'User is already a paid member or part of another team. Please contact support@speedify.com if you think this is incorrect',
    }; })
    .json(() => ({ error: false, isPaid: false }))
    .catch(err => ({ error: true, isPaid: false, context: err.message || err }));
};

export const removeTeamMember = async (referenceId: string, email: string) =>
  api
    .url(`/api/subscription/${referenceId}/teamMember/${email}`)
    .delete()
    .unauthorized(toLogin)
    .unless(204, () => { throw { error: true}; })
    .res(success)
    .catch((err) => { console.error(err); return {error: true }; });

export const purchaseDedicatedServer = async (referenceId: string, timeInterval: string) =>
  api
    .url(`/api/subscription/${referenceId}/server`)
    .post({ timeInterval })
    .unauthorized(toLogin)
    .unless(202, failure)
    .json(success)
    .catch(catcher);

export const upgradeSubscription = async (
  referenceId: string,
  storeType: string,
  quantity: number,
  userid: number,
  email: string,
) => {
  const upgradeSelection = useUpgradeSelection();
  return await api
    .url(`/api/subscription/${referenceId}`)
    .put({
      userid,
      email,
      referenceId,
      storeType,
      newPlanCode: upgradeSelection.planCode,
      quantity,
      prorate: true,
    })
    .unauthorized(toLogin)
    .unless(200, failure)
    .json(success)
    .catch(catcher);
};

export const linkDevice = (userID: number, activationCode: string) =>
  api
    .url('/api/account/linkDevice')
    .patch({
      userid: userID,
      activationCode,
    })
    .unauthorized(toLogin)
    .notFound(failure)
    .unless(200, failure)
    .json(({ deviceUUID }: { deviceUUID: string }) => ({ error: false, message: undefined, deviceUUID }))
    .catch(catcher);

export const getLinkedDevices = () =>
  api
    .url('/api/account/linkedDevices')
    .get()
    .unauthorized(toLogin)
    .unless(200, failure)
    .json()
    .catch(catcher) as any;

export const renameLinkedDevice = (deviceUUID: string, nickname: string) =>
  api
    .url('/api/account/linkedDevices/friendlyName')
    .patch({ deviceUUID, nickname })
    .unauthorized(toLogin)
    .json(identity)
    .catch(catcher);

export const revokeLinkedDevice = async (deviceUUID: string) =>
  api
    .url('/api/account/linkedDevices')
    .json({ deviceUUID })
    .delete()
    .unauthorized(toLogin)
    .unless(200, failure)
    .json(identity)
    .catch(catcher);

export const revokeActiveDevice = (deviceUUID: string) =>
  api
    .url(`/api/account/activeDevice/${deviceUUID}`)
    .delete()
    .unauthorized(toLogin)
    .unless(200, failure)
    .res()
    .catch(catcher);

export const associateRouterWithRouterSub = (deviceUUID: string, referenceId: string) =>
  api
    .url('/api/account/linkedDevice/associate')
    .json({ deviceUUID, referenceId })
    .patch()
    .unauthorized(toLogin)
    .unless(200, failure)
    .res()
    .catch(catcher);

export const dissociateRouterFromRouterSub = (deviceUUID: string) =>
  api
    .url('/api/account/linkedDevice/dissociate')
    .json({ deviceUUID })
    .patch()
    .unauthorized(toLogin)
    .unless(200, failure)
    .res()
    .catch(catcher);

export const associateTeamWithRouterSub = (teamId: number, routerReferenceId: string) =>
  api
    .url('/api/account/team-router/associate')
    .json({ teamId, routerReferenceId })
    .put()
    .unauthorized(toLogin)
    .unless(200, failure)
    .res()
    .catch(catcher);

export const dissociateTeamFromRouterSub = (teamId: number, routerReferenceId: string) =>
  api
    .url('/api/account/team-router/dissociate')
    .json({ teamId, routerReferenceId })
    .delete()
    .unauthorized(toLogin)
    .unless(200, failure)
    .res()
    .catch(catcher);

export interface PricingInfo { // TODO: move me to model.ts
  'speedify-unlimited-yearly': number,
  'speedify-unlimited-monthly': number,
  'speedify-teams-unlimited-monthly': number,
  'speedify-teams-unlimited-yearly': number,
  'speedify-pro-router-3000gb-yearly': number,
  'speedify-pro-router-3000gb-monthly': number,
}

export const getPricingInfo = (): Promise<PricingInfo> =>
  wretch('/api/pricing')
    .get()
    .json(
      obj =>
        Object.fromEntries(
          Object.entries(obj as PricingInfo).map(([k, v]) => [k, Number(v)]),
        ) as any,
    );

export const getCouponForSubscription = (subscriptionId: string): Promise<{ coupon: Coupon, redeemed: boolean }> =>
  api
    .url('/api/recurly/coupon')
    .query({ subscriptionId })
    .get()
    .unauthorized(toLogin)
    .unless(200, failure)
    .json(x => x)
    .catch(catcher);

export const redeemCoupon = (accountId: string, subscriptionId: string, couponId: string) =>
  api
    .url('/api/recurly/redemption')
    .post({ accountId, subscriptionId, couponId })
    .unauthorized(toLogin)
    .unless(200, failure)
    .json(identity)
    .catch(catcher);

export const getTeamsServersInfo = (teamId: string) =>
  api
    .url(`/api/teams/servers/${teamId}`)
    .get()
    .unauthorized(toLogin)
    .unless(200, failure)
    .json(identity)
    .catch(catcher);

export const getPrivateIndividualServersInfo = () =>
  api
    .url(`/api/account/private-individual-servers`)
    .get()
    .unauthorized(toLogin)
    .unless(200, failure)
    .json(identity)
    .catch(catcher);

export const getRouterDetails = (deviceUUID: string) =>
  api
    .url(`/api/partner-router/device/${deviceUUID}`)
    .get();

export const redeemRouterCoupon = (coupon: string) =>
  api
    .url('/api/account/router-coupon-redemption')
    .post({ coupon })
    .unauthorized(toLogin)
    .unless(200, failure)
    .json(identity)
    .catch(catcher);
