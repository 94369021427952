<template>
  <Dialog
    v-model:visible="visible"
    modal
    :closable="true"
    :draggable="false"
    :position="'top'"
    pt:title:class="fw-bold white-space-nowrap mx-auto mt-4 fs-4"
    v-on:hide="clearUrlHash()"
    >
    <template #header>
      <div class="container-fluid router-font-grey">
        <span class="fw-bold" style="color: black">Success!</span>
      </div>
    </template>

    <div class="container-fluid">

      <div class="router-card">
        <h4 class="fw-bold">Your {{vendor}} “Powered By Speedify” router has been activated</h4>
        <div class="my-2">We have automatically added a complimentary 1TB Router license valid for 90 days of service to your account.</div>

        <div class="container">
          <div class="row column-gap-3">
            <Button
              label="OK"
              class="col btn btn-speedify-blue stm-btn-cancelation-flow-go-back-1"
              style="flex: 1 1 0"
              @click="showModal(null)"
            />
          </div>
        </div>
      </div>

    </div>
  </Dialog>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue';
import { useRoute } from 'vue-router';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

import { modal } from '@/symbol';

const route = useRoute();
const { currModal, showModal } = inject(modal)!;
const visible = computed({
  get: () => currModal.value === 'Partner90DayActivated',
  set: () => currModal.value = null,
});

const hash = new URLSearchParams(route.hash.substring(1));
const vendor = hash.get('vendor');

const clearUrlHash = () => history.replaceState(null, '', ' ');
</script>

<style type="text/css" media="screen">
.p-dialog-header {
  padding: 1em 0 0 0 !important;
  border-bottom: 1px solid #e4e7ec;
}

.p-dialog-content {
  padding: 0 0 1em 0 !important;
}

.router-card {
  border: none;
  padding: 1em;
  margin-top: 1em;
}

.p-dialog button {
  border: 1px #F2F4F7 !important;
}

.router-font-grey {
  font-size: 0.8em;
  color: #999999;
}
</style>
