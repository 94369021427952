<template>
  <RouterSubscriptionMissingBanner />
  <OrderCompleteBanner />
  <RouterActivationFlow />
  <!-- eslint-disable vuejs-accessibility/form-control-has-label, vuejs-accessibility/anchor-has-content, vuejs-accessibility/label-has-for -->
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-12 col-lg-7">
        <div class="d-flex justify-content-between">
          <div class="col-lg-6">
            <h2>Licenses</h2>
          </div>
          <div class="col-lg-6">
            <input
              v-if="subscriptions.length > 3"
              id="subscription-search"
              v-model="state.subscriptionSearchString" class="form-control"
              type="text" name="subscription-search"
              placeholder="Filter subscriptions" data-bs-toggle="tooltip" data-bs-placement="top"
              title="You may search for a product, a type, or an email of a subscription's owner"
            >
          </div>
        </div>
        <div v-if="subscriptions.length > 0" id="subscriptions-data" class="">
          <div
            v-for="subscription in filterSubscription"
            id="subscriptions-list"
            :key="subscription.referenceId" class="mt-3"
          >
            <ProductCard :subscription="subscription" :user="user" />
          </div>
        </div>
        <div v-else>
          <div
            id="subscriptions-list"
            class="mt-3"
          >
            <FreeProductCard :usedDataGB="usedDataGB" :freeLicenseDataGB="freeLicenseDataGB" />
          </div>
        </div>
        <div
          v-if="config.subsmgmt.enableLinkedDevicesView && (user.linkedDevices.length > 0 || user.activeDevices.length > 0)"
          id="linked-devices-data"
          class="mt-5"
        >
          <h2>
            Sessions
          </h2>
          <small>
            You can sign in and use this account on up to {{ userStore.customActiveDeviceLimit }} devices per week
          </small>
          <DeviceSessionTable
            class="mt-2"
            :activeDevices="user.activeDevices"
            :linkedDevices="user.linkedDevices"
            @handleShowModal="handleShowModal"
          />
        </div>
      </div>
      <div class="col-sm">
        <div>
          <h2>Your Account</h2>
        </div>
        <div>
          <div class="card my-4">
            <div class="card-body">
              <div class="card-title mt-2">
                <small>Account Email</small>
              </div>
              <h5 class="text-break">{{ user.email }}</h5>
              <hr>
              <!-- Change password modal trigger -->
              <div class="d-flex flex-row justify-content-around">
                <!-- TODO: remove in-house password change code after migration -->
                <a style="text-decoration: none">
                  <button
                    type="button"
                    class="btn btn-speedify-blue stm-btn-change-password-attempt"
                    @click="handleShowModal('changePasswordModal')"
                  >
                    Change Password
                  </button>
                </a>
                <router-link v-if="config.subsmgmt.enableLinkedDevicesView" to="/activate" style="text-decoration: none">
                  <button
                    type="button"
                    class="btn btn-speedify-blue stm-btn-link-device-attempt"
                  >
                    Link a Device
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <h2 class="mt-3">Links</h2>
        <div id="live-stream-card" class="card my-4">
          <div class="card-body">
            <div class="row ">
              <div class="col-sm-12 d-flex justify-content-center mb-3">
                <h4 class="my-3">
                  Download Speedify
                </h4>
              </div>
            </div>
            <div class="mb-3">
              <a href="https://speedify.com/download/" target="_blank" rel="noopener noreferrer">
                <img
                  src="https://speedify.com/wp-content/uploads/speedify-platforms-02-1.png"
                  class="card-img download-platforms"
                  alt="Download Speedify"
                >
              </a>
            </div>
          </div>
        </div>
        <div id="live-stream-card" class="card my-4">
          <div class="card-body">
            <div>
              <a href="https://speedify.com/events/" target="_blank" rel="noopener noreferrer">
                <img src="https://speedify.com/wp-content/uploads/Office-Hours-subsmgmt-tr.png" class="card-img" alt="Speedify LIVE">
              </a>
            </div>
            <div class="my-2">
              <p>
                Every week, chat LIVE with Speedify CEO Alex Gizis and our developers about the latest news and updates with Speedify,
                and hit them with your hardest Speedify and networking tech questions - only on Speedify LIVE Office Hours.
              </p>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <a
                  href="https://speedify.com/events/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-speedify-blue stm-btn-speedify-live-ad-click"
                >Watch us LIVE</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- change password modal -->
  <!-- TODO: remove this modal once we compltely migrated to zitadel -->
  <div
    v-if="state.showChangePasswordModal"
    id="changePasswordModal"
    class="modal fade"
    data-bs-backdrop="static"
    tabindex="-1"
    aria-labelledby="changePasswordModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="changePasswordModal" class="modal-title">Change Password</h5>
          <button
            type="button"
            class="btn-close stm-btn-change-password-cancel"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="dismissChangePasswordModal"
          />
        </div>
        <form @submit.prevent="changePassword">
          <div class="modal-body">
            <!-- Current password input -->
            <div class="form-floating">
              <input
                id="currentPasswordInput"
                v-model="state.changePassword.currentPassword"
                type="password"
                class="form-control" placeholder="Current Password" aria-label="Current Password"
                aria-describedby="currentPassword" @change="changePasswordOnChangeHandler" @click="registerClick('currentPassword')"
              />
              <label for="currentPasswordInput">
                Current Password
              </label>
            </div>
            <small
              v-if="!v$.state.changePassword.currentPasswordClicked.mustBeTrue.$invalid && v$.state.changePassword.currentPassword.required.$invalid"
              style="color: red;">
              Password is required.
            </small>

            <!-- new password input -->
            <div class="form-floating mt-3">
              <input
                id="newPasswordInput"
                v-model="state.changePassword.newPassword"
                type="password"
                class="form-control" placeholder="New Password" aria-label="New Password"
                aria-describedby="newPassword" @change="changePasswordOnChangeHandler" @click="registerClick('newPassword')"
              />
              <label for="newPasswordInput">
                New Password
              </label>
            </div>
            <small
              v-if="!v$.state.changePassword.newPasswordClicked.mustBeTrue.$invalid && v$.state.changePassword.newPassword.required.$invalid"
              style="color: red;"
            >
              Password is required.
            </small>
            <small v-if="v$.state.changePassword.newPassword.minLength.$invalid" style="color: red;">Password must have at least 8 characters.</small>

            <!-- repeat new password input -->
            <div class="form-floating mt-3">
              <input
                id="repeatPasswordInput"
                v-model="state.changePassword.repeatNewPassword"
                type="password"
                class="form-control" placeholder="Repeat New Password" aria-label="Repeat New Password"
                aria-describedby="repeatNewPassword" @change="changePasswordOnChangeHandler" @click="registerClick('repeatNewPassword')"
              />
              <label for="repeatPasswordInput">
                Repeat New Password
              </label>
            </div>
            <small
              v-if="v$.state.changePassword.repeatNewPassword.sameAsNewPassword.$invalid"
              style="color: red;"
            >
              Passwords must be identical.
            </small>

            <!-- alerts for change password modal -->
            <div v-if="state.changePassword.success" class="alert alert-success mt-3 stm-msg-change-password-success" role="alert">
              <i class="fas fa-check-circle"></i>&nbsp;Password has been successfully changed!
            </div>
            <div v-if="state.changePassword.failed" class="alert alert-danger mt-3 stm-msg-change-password-error" role="alert">
              <i class="fas fa-exclamation-circle"></i>&nbsp;Error: Unable to update password
              <hr>
              {{ state.changePassword.failureError }}
            </div>
          </div>

          <div class="modal-footer">
            <button
              v-if="state.changePassword.success"
              type="button"
              class="btn btn-speedify-blue stm-btn-change-password-done"
              data-bs-dismiss="modal"
              @click="dismissChangePasswordModal"
            >
              Done
            </button>
            <button
              v-if="!state.changePassword.success"
              type="button"
              class="btn btn-secondary stm-btn-change-password-cancel"
              data-bs-dismiss="modal"
              @click="dismissChangePasswordModal"
            >
              Cancel
            </button>
            <button
              v-if="!state.changePassword.success"
              type="submit"
              class="btn btn-speedify-blue stm-btn-change-password-confirm"
              :class="{
                'disabled': v$.state.changePassword.currentPassword.required.$invalid
                  || v$.state.changePassword.newPassword.required.$invalid
                  || v$.state.changePassword.newPassword.minLength.$invalid
                  || v$.state.changePassword.repeatNewPassword.sameAsNewPassword.$invalid
              }"
            >
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Rename linked devices modal -->
  <RenameDeviceModal
    v-if="state.showRenameDeviceModal"
    :uuid="state.deviceUUID"
    :nickname="state.currentNickname"
    @getLinkedDevices="refreshLinkedDevices"
    @dismissModal="dismissModal"
  />

  <!-- Revoke Active Device Modal -->
  <RevokeActiveDeviceModal
    v-if="state.showRevokeActiveDeviceModal"
    :uuid="state.deviceUUID"
    :nickname="state.currentNickname"
    @dismissModal="dismissModal"
  />

  <!-- Revoke Linked Device Modal -->
  <RevokeDeviceModal
    v-if="state.showRevokeDeviceModal"
    :uuid="state.deviceUUID"
    :nickname="state.currentNickname"
    @getLinkedDevices="refreshLinkedDevices"
    @dismissModal="dismissModal"
  />
</template>

<script lang="ts" setup>
import { reactive, computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, minLength, sameAs } from '@vuelidate/validators';
import { storeToRefs } from 'pinia';

import RouterSubscriptionMissingBanner from '@/components/RouterSubscriptionMissingBanner.vue';
import OrderCompleteBanner from '@/components/OrderCompleteBanner.vue';
import DeviceSessionTable from '@/components/DeviceSessionsTable.vue';
import RenameDeviceModal from '@/components/RenameDeviceModal.vue';
import RevokeDeviceModal from '@/components/RevokeDeviceModal.vue';
import RevokeActiveDeviceModal from '@/components/RevokeActiveDeviceModal.vue';
import ProductCard from '@/components/ProductCard.vue';
import { useUserStore } from '@/store/user';
import { config } from '@/config';
import { changeUserPassword } from '@/services/backend';
import { showModal } from '@/services/modal';
import FreeProductCard from '@/components/FreeProductCard.vue';
import RouterActivationFlow from '@/components/RouterActivationFlow.vue';

const userStore = useUserStore();
const state = reactive({
  subscriptionSearchString: '',
  showChangePasswordModal: false,
  changePassword: {
    currentPassword: '',
    newPassword: '',
    repeatNewPassword: '',
    currentPasswordClicked: false,
    newPasswordClicked: false,
    repeatNewPasswordClicked: false,
    success: false,
    failed: false,
    failureError: '',
  },
  showRenameModal: false,
  showRenameDeviceModal: false,
  showRevokeDeviceModal: false,
  showRevokeActiveDeviceModal: false,
  deviceUUID: '',
  currentNickname: '',
});

const {
  userid,
  email,
  activeDevices,
  linkedDevices,
  subscriptions,
  freeLicenseDataGB,
  usedDataGB,
} = storeToRefs(userStore);

const user = computed(() => ({
  userid: userid.value,
  email: email.value,
  activeDevices: activeDevices.value,
  linkedDevices: linkedDevices.value,
}));

// FIXME: https://github.com/Connectify/speedify-subs-mgmt-v2/issues/767
const handleShowModal = (elementID: string, deviceUUID: string = '', friendlyName: string = '') => {
  // @ts-ignore
  state[`show${elementID.charAt(0).toUpperCase()}${elementID.slice(1)}`] = true;
  state.deviceUUID = deviceUUID;
  state.currentNickname = friendlyName;
  showModal(elementID);
};

const filterSubscription = computed(() => {
  if (state.subscriptionSearchString === '') {
    return subscriptions.value;
  }
  return subscriptions.value.filter((s) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    s.productName?.match(state.subscriptionSearchString)
    || s.type?.match(state.subscriptionSearchString)
    || s.ownerEmail?.toLowerCase().match(state.subscriptionSearchString.replace('+', '\\+').toLowerCase()));
});

const changePasswordOnChangeHandler = () => {
  state.changePassword.success = false;
  state.changePassword.failed = false;
  state.changePassword.failureError = '';
};

const dismissChangePasswordModal = () => {
  state.showChangePasswordModal = false;
  state.changePassword.currentPassword = '';
  state.changePassword.newPassword = '';
  state.changePassword.repeatNewPassword = '';
  state.changePassword.success = false;
  state.changePassword.failed = false;
  state.changePassword.failureError = '';
  state.changePassword.currentPasswordClicked = false;
  state.changePassword.newPasswordClicked = false;
  state.changePassword.repeatNewPasswordClicked = false;
};

const changePassword = async () => {
  const response = await changeUserPassword(state.changePassword.currentPassword, state.changePassword.newPassword);

  if (response.error) {
    state.changePassword.success = false;
    state.changePassword.failed = true;
    // @ts-ignore
    state.changePassword.failureError = response.message;
  } else {
    state.changePassword.success = true;
    state.changePassword.failed = false;
    state.changePassword.failureError = '';
  }
};

const refreshLinkedDevices = () => userStore.refreshLinkedDevices();

const dismissModal = () => {
  state.showRenameDeviceModal = false;
  state.showRevokeDeviceModal = false;
  state.showRevokeActiveDeviceModal = false;
  state.deviceUUID = '';
  state.currentNickname = '';
};

const mustBeTrue = (value: any) => value === true;

const rules = computed(() => ({
  state: {
    changePassword: {
      currentPassword: {
        required,
      },
      newPassword: {
        required,
        minLength: minLength(8),
      },
      repeatNewPassword: {
        sameAsNewPassword: sameAs(state.changePassword.newPassword),
      },
      currentPasswordClicked: {
        mustBeTrue,
      },
      newPasswordClicked: {
        mustBeTrue,
      },
      repeatNewPasswordClicked: {
        mustBeTrue,
      },
    },
    renameDevice: {
      nickname: {
        required,
      }
    }
  },
}));

/*
  We don't want vuelidate to complain to the user immediately,
  so let's wait until the user clicked an input to display issues
*/
const registerClick = (element: string) => {
  // @ts-ignore
  state.changePassword[`${element}Clicked`] = true;
};

const v$ = useVuelidate(rules, { state });
</script>
