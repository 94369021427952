<template>
  <!-- Change Router Quantity Modal -->
  <div
    id="RouterQtyChangeModal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="RouterQtyChangeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="RouterQtyChangeModalLabel" class="modal-title fs-5">Update Router Quantity</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="dismissModal"></button>
        </div>
        <div class="modal-body">
          This subscription currently has {{ subscription!.quantity }} router(s) supported
          <br />
          You may change the quantity of routers that this plan is associated with
          <form class="form-floating" @submit.prevent="changeSubscriptionQuantity">
            <input
              id="changeRouterQuantityInput"
              v-model="state.modal.updateQuantity.quantity"
              type="number"
              class="form-control"
              :placeholder="`${subscription!.quantity}`"
              :min="1"
            />
            <label for="changeSeatsInput">Change quantity of licenses (min: 1)</label>
            <small
              v-if="
                v$.modal.updateQuantity.quantity.required.$invalid
                || v$.modal.updateQuantity.quantity.integer.$invalid
                || v$.modal.updateQuantity.quantity.minValue.$invalid
              "
              style="color: red;"
            >
              Please enter a valid integer larger than the minimum ({{ subscription!.quantity }})
            </small>
          </form>
        </div>
        <div class="modal-footer">
          <button
            v-if="state.modal.updateQuantity.success"
            type="button"
            class="btn btn-secondary stm-btn-modify-router-quantity-done"
            data-bs-dismiss="modal"
            @click="dismissModal"
          >
            Done
          </button>
          <button
            v-if="!state.modal.updateQuantity.success"
            type="button"
            class="btn btn-secondary stm-btn-modify-router-quantity-cancel"
            data-bs-dismiss="modal"
            @click="dismissModal"
          >
            Cancel
          </button>
          <button
            v-if="!state.modal.updateQuantity.success && !state.modal.updateQuantity.error"
            type="submit"
            class="btn btn-speedify-blue stm-btn-modify-router-quantity-confirm"
            :class="{ disabled: isNaN(state.modal.updateQuantity.quantity)
                || state.modal.updateQuantity.quantity === subscription!.quantity
                || v$.modal.updateQuantity.quantity.required.$invalid
                || v$.modal.updateQuantity.quantity.integer.$invalid
                || v$.modal.updateQuantity.quantity.minValue.$invalid
            }"
            @click="changeSubscriptionQuantity()"
          >
            <span v-if="state.modal.updateQuantity.pending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Update Router Quantity
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Rename linked devices modal -->
  <RenameDeviceModal
    v-if="state.modal.rename.show"
    :uuid="state.modal.uuid"
    :nickname="state.modal.nickname"
    @getLinkedDevices="refreshLinkedDevices"
    @dismissModal="dismissModal"
  />

  <!-- Revoke Linked Device Modal -->
  <RevokeDeviceModal
    v-if="state.modal.revoke.show"
    :uuid="state.modal.uuid"
    :nickname="state.modal.nickname"
    @getLinkedDevices="refreshLinkedDevices"
    @dismissModal="dismissModal"
  />

  <div class="container mt-5">
    <nav aria-label="breadcrumb my-3">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link :to="{ name: 'AccountDashboard' }">Dashboard</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">Manage Routers</li>
      </ol>
    </nav>
    <h1 class="mb-3">Manage Routers</h1>

    <div class="row my-2">
      <div class="col-sm">
        <div class="card mb-3">
          <div class="card-body pb-4">
            <div class="card-title">
              <small>Owner</small>
            </div>
            <h5>{{ subscription!.ownerEmail }}</h5>
          </div>
        </div>
      </div>
      <div class="col-sm mb-3">
        <div id="email" class="card">
          <div class="card-body pb-4">
            <div class="card-title">
              <small>Renews on</small>
            </div>
            <h5>{{ new Date(subscription!.nextPeriodDate!).toDateString() }}</h5>
            </div>
        </div>
      </div>
    </div>

    <div class="row my-2">
      <div class="col-sm">
        <div class="card mb-3">
          <div class="card-body pb-4">
            <div class="card-title">
              <small>Linked Router(s)</small>
            </div>
            <h5>{{ dataTableAssociatedRouters.length }}</h5>
            <!--
              no video exists yet so there is no modal showing it. for now it will be disabled
              https://www.figma.com/file/xx2hTz1Vu681dYSzG7D8Nt/my.speedify.com?type=design&node-id=4059-13665&mode=design
            -->
            <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
            <a
              type="button"
              class="text-decoration-none stm-btn-modify-router-info"
              disabled
            >
              <small>Link a Router</small>
            </a>
          </div>
        </div>
      </div>
      <div class="col-sm mb-3">
        <div id="email" class="card">
          <div class="card-body pb-4">
            <div class="card-title">
              <small>Router License(s)</small>
            </div>
            <h5>{{ subscription!.quantity }}</h5>
            <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
            <a
              type="button"
              class="text-decoration-none stm-btn-modify-router-quantity"
              data-bs-toggle="modal"
              data-bs-target="#RouterQtyChangeModal"
            >
              <small>Add/Remove Licenses</small>
            </a>
            </div>
        </div>
      </div>
    </div>

    <!-- table! -->
    <h2 class="mt-5">Routers ({{ dataTableAssociatedRouters.length }}/{{ subscription!.quantity }})</h2>
    <small>Routers currently linked to your account</small>

    <div class="card mt-3">
      <div class="card-body">
        <DataTable :value="dataTableRouters" responsiveLayout="scroll" class="p-datatable-sm">
          <Column header="Router Name" :sortable="true">
            <template #body="slotProps">
              <div
                :style="
                  isRouterAssociationDisabled
                  && slotProps.data.routerReferenceId !== subscription!.referenceId
                  ? 'color: grey' : ''
                "
              >
                {{ slotProps.data.friendlyName || 'No name specified' }}
              </div>
            </template>
          </Column>
          <Column header="Last Active" :sortable="true">
            <template #body="slotProps">
              <div
                :style="
                  isRouterAssociationDisabled
                  && slotProps.data.routerReferenceId !== subscription!.referenceId
                  ? 'color: grey' : ''
                "
              >
                {{ slotProps.data.lastActiveTime }}
              </div>
            </template>
          </Column>
          <Column class="font-monospace" field="deviceUUID" header="UUID" :sortable="true">
            <template #body="slotProps">
              <div
                :style="
                  isRouterAssociationDisabled
                  && slotProps.data.routerReferenceId !== subscription!.referenceId
                  ? 'color: grey' : ''
                "
              >
                {{ slotProps.data.deviceUUID }}
              </div>
            </template>
          </Column>
          <Column header="Register" :sortable="false">
            <template #body="slotProps">
              <input
                id="routerAssociationTruth"
                class="form-check-input"
                type="checkbox"
                style="border: solid 1.5px gray;"
                :checked="slotProps.data.routerReferenceId !== null"
                :disabled="
                  slotProps.data.routerReferenceId !== subscription!.referenceId && dataTableAssociatedRouters.length >= subscription!.quantity
                "
                @click.prevent="toggleRouterAssociationWithSub(slotProps.data.deviceUUID, $event)"
              />
            </template>
          </Column>
          <Column
            headerStyle="width: 4rem; text-align: center"
            bodyStyle="text-align: center; overflow: visible"
          >
            <template #body="slotProps">
              <FlatGarbageButtonRed
                @click="handleRevokeClick(slotProps.data.deviceUUID, slotProps.data.friendlyName)"
              />
            </template>
          </Column>
          <Column
            headerStyle="width: 4rem; text-align: center"
            bodyStyle="text-align: center; overflow: visible"
          >
            <template #body="slotProps">
              <FlatEditButton
                @click="handleRenameClick(slotProps.data.deviceUUID)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue';
import { useRoute } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import {
  required,
  integer,
  minValue,
} from '@vuelidate/validators';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import { useUserStore } from '@/store/user';
import { changeQuantity, associateRouterWithRouterSub, dissociateRouterFromRouterSub } from '@/services/backend';
import { showModal } from '@/services/modal';
import RenameDeviceModal from '@/components/RenameDeviceModal.vue';
import RevokeDeviceModal from '@/components/RevokeDeviceModal.vue';
import FlatEditButton from '@/components/buttons/FlatEditButton.vue';
import FlatGarbageButtonRed from '@/components/buttons/FlatGarbageButtonRed.vue';

const route = useRoute();

const userStore = useUserStore();
const { referenceId } = route.query;
const subscription = computed(() => userStore.subscriptions.find((s) => s.referenceId === referenceId));
const dataTableRouters = userStore.routers.filter((r) =>
  r.routerReferenceId === referenceId || r.routerReferenceId === null
);
const dataTableAssociatedRouters = computed(() =>
  userStore.routers.filter((r) => r.routerReferenceId === referenceId)
);

const state = reactive({
  modal: {
    uuid: '',
    nickname: '',
    rename: {
      show: false,
    },
    revoke: {
      show: false,
    },
    updateQuantity: {
      quantity: subscription.value!.quantity,
      pending: false,
      error: false,
      errorContext: '',
      success: false,
    },
  },
});

const rules = computed(() => ({
  modal: {
    updateQuantity: {
      quantity: {
        required,
        integer,
        minValue: minValue(subscription.value!.quantity),
      },
    },
  },
}));
const v$ = useVuelidate(rules, state);

const changeSubscriptionQuantity = async () => {
  state.modal.updateQuantity.pending = true;
  const result = await changeQuantity(subscription.value!.referenceId, state.modal.updateQuantity.quantity);
  state.modal.updateQuantity.pending = false;
  if (result.error) {
    state.modal.updateQuantity.error = true;
    // @ts-ignore
    state.update.errorContext = result.response;
    return;
  }
  state.modal.updateQuantity.success = true;
  userStore.updateSeatQuantity(subscription.value!.referenceId, state.modal.updateQuantity.quantity);
};

const refreshLinkedDevices = () => userStore.refreshLinkedDevices();

const dismissModal = () => {
  state.modal.rename.show = false;
  state.modal.revoke.show = false;
  state.modal.uuid = '';
  state.modal.nickname = '';
  state.modal.updateQuantity.quantity = subscription.value!.quantity;
  state.modal.updateQuantity.success = false;
  state.modal.updateQuantity.error = false;
  state.modal.updateQuantity.pending = false;
  state.modal.updateQuantity.errorContext = '';
};

const handleRenameClick = (deviceUUID: string) => {
  state.modal.uuid = deviceUUID;
  state.modal.rename.show = true;
  showModal('renameDeviceModal');
};

const handleRevokeClick = (deviceUUID: string, nickname: string) => {
  state.modal.uuid = deviceUUID;
  state.modal.nickname = nickname;
  state.modal.revoke.show = true;
  showModal('revokeDeviceModal');
};

const isRouterAssociationDisabled = computed(() => {
  return dataTableAssociatedRouters.value.length >= subscription.value!.quantity
});

const toggleRouterAssociationWithSub = async (uuid: string, e: any) => {
  if (e.target.checked) {
    await associateRouter(uuid);
  } else {
    await dissociateRouter(uuid);
  }
};

const associateRouter = async (uuid: string) => {
  const result = await associateRouterWithRouterSub(uuid, referenceId as string);
  if (!result.error) {
    userStore.updateLinkedDeviceAssociation(uuid, referenceId as string);
  } else {
    userStore.updateLinkedDeviceAssociation(uuid, null);
  }
};

const dissociateRouter = async (uuid: string) => {
  const result = await dissociateRouterFromRouterSub(uuid);
  if (!result.error) {
    userStore.updateLinkedDeviceAssociation(uuid, null);
  }
};
</script>
