<template>
  <Dialog
    v-model:visible="visible"
    modal
    :closable="true"
    :draggable="false"
    :position="'top'"
    pt:title:class="fw-bold white-space-nowrap mx-auto mt-4 fs-4"
    >
    <template #header>
      <div class="container-fluid router-font-grey">
        <span class="fw-bold">Redeem an Activation Code</span>
      </div>
    </template>

    <div class="container-fluid">

      <div class="router-card">
        <InputText v-model="value" type="text" :invalid="invalid" placeholder="Enter your Activation Code" v-on:input="update" />
        <div v-if="invalid" style="color: #ff4f44">Invalid code</div>

        <div class="container mt-1">
          <div class="row column-gap-3">
            <Button
              label="Go Back"
              class="col btn btn-speedify-grey stm-btn-cancelation-flow-go-back-1"
              style="flex: 1 1 0"
              @click="showModal(null)"
            />
            <Button
              label="Redeem"
              class="col btn btn-speedify-blule stm-btn-cancelation-flow-go-back-1"
              style="flex: 1 1 0"
              @click="redeem()"
            />
          </div>
        </div>
      </div>

    </div>
  </Dialog>
</template>

<script lang="ts" setup>
import { computed, inject, ref } from 'vue';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { redeemRouterCoupon } from '@/services/backend';

import { modal } from '@/symbol';

const value = ref('');
const { currModal, showModal } = inject(modal)!;
const visible = computed({
  get: () => currModal.value === 'RedeemCode',
  set: () => currModal.value = null,
});
const invalid = ref(false);

const redeem = async () => {
  const result = await redeemRouterCoupon(value.value)

  if (result.error) {
    invalid.value = true;
  } else {
    showModal('Activated');
  }
};

const update = () => {
  invalid.value = false;
};
</script>

<style type="text/css" media="screen">
.p-dialog-header {
  padding: 1em 0 0 0 !important;
  border-bottom: 1px solid #e4e7ec;
}

.p-dialog-content {
  padding: 0 0 1em 0 !important;
}

.router-card {
  border: 1px solid #E4E7EC;
  border-radius: 10px;
  padding: 1em;
  margin-top: 1em;
}

.p-dialog button {
  border: 1px #F2F4F7 !important;
}

.router-font-grey {
  font-size: 0.8em;
  color: #999999;
}
</style>
