<template>
  <Dialog
    v-model:visible="visible"
    modal
    :closable="true"
    :draggable="false"
    :position="'top'"
    pt:title:class="fw-bold white-space-nowrap mx-auto mt-4 fs-4"
    v-on:hide="clearUrlHash()"
  >
    <template #header>
      <div class="container-fluid router-font-grey">
        <span class="fw-bold" style="color: #ff4f44">This device requires a Speedify for Routers license</span>
        <br>
        <span>In order to continue, add an additional Speedify for Routers license to your account or
          <a href="/account">unlink an existing device</a>.</span>
          <!-- just a link to the session table -->
      </div>
    </template>

    <div class="container-fluid">

      <div class="router-card">
        <h4 class="fw-bold">Speedify for Routers 1TB</h4>
        <span class="router-font-grey">Activate your “Powered by Speedify” Router and share the bonded super connection with
          all devices on your network.</span>
        <div class="my-2">Includes 1TB of recurring monthly data</div>

        <div class="container">
          <div class="row column-gap-3">
            <Button
              label="$30 Monthly"
              class="col btn btn-speedify-grey stm-btn-cancelation-flow-pause-1"
              style="flex: 1 1 0"
              @click="buy('speedify-pro-router-1000gb-monthly')"
            />
            <Button
              label="$300 Yearly"
              class="col btn btn-speedify-grey stm-btn-cancelation-flow-pause-1"
              style="flex: 1 1 0"
              @click="buy('speedify-pro-router-1000gb-yearly')"
            />
          </div>
        </div>
      </div>

      <div class="router-card">
        <h4 class="fw-bold">Speedify for Routers 3TB</h4>
        <span class="router-font-grey">Activate your “Powered by Speedify” Router and share the bonded super connection with
          all devices on your network.</span>
        <br>
        <!-- TODO: link to in-app purchase -->
        <div class="my-2">Includes 3TB of recurring monthly data</div>

        <div class="container">
          <div class="row column-gap-3">
            <Button
              label="$45 Monthly"
              class="col btn btn-speedify-grey stm-btn-cancelation-flow-pause-1"
              style="flex: 1 1 0"
              @click="buy('speedify-pro-router-3000gb-monthly')"
            />
            <Button
              label="$450 Yearly"
              class="col btn btn-speedify-grey stm-btn-cancelation-flow-pause-1"
              style="flex: 1 1 0"
              @click="buy('speedify-pro-router-3000gb-yearly')"
            />
          </div>
        </div>
      </div>

      <div class="router-card">
        <h4 class="fw-bold">Redeem an Activation Code</h4>
        <span class="router-font-grey">Did your purchase a license from a vendor or reseller?</span>

        <div class="container">
          <div class="row column-gap-3">
            <Button
              label="Redeem a Code"
              class="col btn btn-speedify-grey stm-btn-cancelation-flow-pause-1"
              style="flex: 1 1 0"
              @click="showModal('RedeemCode')"
            />
          </div>
        </div>
      </div>

    </div>
  </Dialog>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue';
import { useRouter } from 'vue-router';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

import { modal } from '@/symbol';

const router = useRouter();
const { currModal, data, showModal } = inject(modal)!;
const visible = computed({
  get: () => currModal.value === 'PlansPricing',
  set: () => currModal.value = null,
});

const buy = async (code: string) => {
  showModal('PlanConfirmation');
  data.value = { 'router-purchase-plan': code };
  void await router.replace({ ...router.currentRoute, hash: `#router-purchase-plan=${code}` });
}

const clearUrlHash = () => history.replaceState(null, '', ' ');
</script>

<style type="text/css" media="screen">
.p-dialog-header {
  padding: 1em 0 0 0 !important;
}

.p-dialog-content {
  padding: 0 0 1em 0 !important;
}

.router-card {
  border: 1px solid #E4E7EC;
  border-radius: 10px;
  padding: 1em;
  margin-top: 1em;
}

.p-dialog button {
  border: 1px #F2F4F7 !important;
}

.router-font-grey {
  font-size: 0.8em;
  color: #999999;
}
</style>
