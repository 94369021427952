<template>
  <RouterPlansPricing />
  <RouterPlanConfirmation />
  <RouterRedeemCode />
  <RouterActivated />
  <RouterPartner90DayActivated />
</template>

<script lang="ts" setup>
import { provide, onMounted, ref } from 'vue';

import RouterPlansPricing from '@/components/RouterPlansPricing.vue';
import RouterPlanConfirmation from '@/components/RouterPlanConfirmation.vue';
import RouterRedeemCode from '@/components/RouterRedeemCode.vue';
import RouterActivated from '@/components/RouterActivated.vue';
import RouterPartner90DayActivated from '@/components/RouterPartner90DayActivated.vue';
import { modal } from '@/symbol';

const currModal = ref<string | null>(null);
const data = ref<any>({});
const showModal = (name: string | null) => (currModal.value = name);
provide(modal, { currModal, data, showModal });

onMounted(() => {
  const hash = new URLSearchParams(window.location.hash.substring(1));
  if (hash.get('partner-90-day-activated') === 'true') {
    return showModal('Partner90DayActivated');
  }

  if (hash.get('show-router-dialogue') === 'true') {
    return showModal('PlansPricing');
  }

  if (hash.get('router-purchase-plan')) {
    return showModal('PlanConfirmation');
  }
});

window.addEventListener('hashchange', () => {
  const hash = new URLSearchParams(window.location.hash.substring(1));

  if (hash.get('partner-90-day-activated') === 'true') {
    return showModal('Partner90DayActivated');
  }

  if (hash.get('show-router-dialogue') === 'true') {
    return showModal('PlansPricing');
  }

  if (hash.get('router-purchase-plan')) {
    return showModal('PlanConfirmation');
  }
});
</script>
